import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  Tooltip,
  Paper,
  Card,
  CardContent,
  Chip,
  Divider,
  Switch,
  Fade,
  Grow,
  useTheme,
  alpha,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useToast } from '../../../../contexts/ToastContext'
import axiosInstance from '../../../../utils/axiosInstance'
import Loading from '../../../../ui-component/loading/Loading'

// Integration Provider options with icons
const INTEGRATION_PROVIDERS = [
  { value: 'hr4you', label: 'HR4YOU', icon: '🔷' },
  { value: 'concludis-internal', label: 'Concludis Internal', icon: '🟢' },
  { value: 'onlyfy', label: 'Onlyfy', icon: '🟠' },
]

interface IntegrationConfig {
  username?: string
  password?: string
  companyIdentifier?: string
  apiKey?: string
}

interface IntegrationData {
  config: IntegrationConfig
  active: boolean
  type: string
  autoLeadPush?: boolean
}

interface Integration {
  id: string
  externalId: string | null
  type: string
  config: IntegrationConfig
  active: boolean
  jobCount?: number
  schedule?: string
  autoLeadPush?: boolean
}

interface ImportJobsResponse {
  message: string
  count: number
  jobs: any[]
}

export const Integrations = ({ id }: { id: string }) => {
  const [selectedProvider, setSelectedProvider] = useState('')
  const [config, setConfig] = useState<IntegrationConfig>({})
  const [loading, setLoading] = useState(false)
  const [existingIntegrations, setExistingIntegrations] = useState<Integration[]>([])
  const [loadingIntegrations, setLoadingIntegrations] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [integrationToDelete, setIntegrationToDelete] = useState<string | null>(null)
  const [syncSettings, setSyncSettings] = useState<{
    [key: string]: { enabled: boolean; minutes: number; autoLeadPush: boolean }
  }>({})
  const [showSyncSettings, setShowSyncSettings] = useState<{ [key: string]: boolean }>({})

  const intl = useIntl()
  const { showToast } = useToast()
  const theme = useTheme()

  // Custom theme extensions
  const customColors = {
    primary: {
      soft: alpha(theme.palette.primary.main, 0.08),
      lighter: alpha(theme.palette.primary.main, 0.16),
    },
    background: {
      neutral: alpha(theme.palette.grey[500], 0.08),
    },
    info: {
      light: alpha(theme.palette.info.main, 0.1),
      soft: alpha(theme.palette.info.main, 0.08),
    },
    error: {
      soft: alpha(theme.palette.error.main, 0.1),
    },
    success: {
      soft: alpha(theme.palette.success.main, 0.08),
    },
    text: {
      disabled: alpha(theme.palette.text.disabled, 0.08),
    },
  }

  // Load existing integrations when component mounts
  useEffect(() => {
    if (id) {
      fetchExistingIntegrations()
    }
  }, [id])

  // Initialize sync settings when integrations are loaded
  useEffect(() => {
    const initialSyncSettings: {
      [key: string]: { enabled: boolean; minutes: number; autoLeadPush: boolean }
    } = {}
    existingIntegrations.forEach((integration) => {
      initialSyncSettings[integration.id] = {
        enabled: !!integration.schedule && integration.schedule !== '',
        minutes: getSyncIntervalMinutes(integration.schedule) || 30,
        autoLeadPush: integration.autoLeadPush || false,
      }
    })
    setSyncSettings(initialSyncSettings)
  }, [existingIntegrations])

  const fetchExistingIntegrations = async () => {
    setLoadingIntegrations(true)
    try {
      const response = await axiosInstance.get(`/integrations/configurations/customer/${id}`)
      const integrationsWithJobs = await Promise.all(
        response.data.map(async (integration: Integration) => {
          try {
            // Get job count for each integration
            const jobsResponse = await axiosInstance.get(
              `/integrations/configurations/${integration.id}/jobs/count`
            )
            return {
              ...integration,
              jobCount: jobsResponse.data.count,
            }
          } catch (err) {
            console.error(`Error fetching job count for integration ${integration.id}:`, err)
            return {
              ...integration,
              jobCount: 0,
            }
          }
        })
      )
      setExistingIntegrations(integrationsWithJobs)
    } catch (err) {
      console.error('Error fetching integrations:', err)
      showToast(
        intl.formatMessage({
          id: 'integration-load-error',
          defaultMessage: 'Error loading integrations',
        }),
        'error'
      )
    } finally {
      setLoadingIntegrations(false)
    }
  }

  const handleProviderChange = (event: SelectChangeEvent) => {
    const newProvider = event.target.value
    if (existingIntegrations.some((integration) => integration.type === newProvider)) {
      showToast(
        intl.formatMessage({
          id: 'integration-type-exists',
          defaultMessage: 'Integration type already exists',
        }),
        'error'
      )
      return
    }
    setSelectedProvider(newProvider)
    setConfig({}) // Reset config when provider changes
  }

  const handleConfigChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig((prev) => ({
      ...prev,
      [field]: event.target.value,
    }))
  }

  const renderConfigFields = () => {
    switch (selectedProvider) {
      case 'hr4you':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={config.username || ''}
                onChange={handleConfigChange('username')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={config.password || ''}
                onChange={handleConfigChange('password')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
            </Grid>
          </Grid>
        )
      case 'concludis-internal':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Identifier"
                value={config.companyIdentifier || ''}
                onChange={handleConfigChange('companyIdentifier')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={config.username || ''}
                onChange={handleConfigChange('username')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={config.password || ''}
                onChange={handleConfigChange('password')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
            </Grid>
          </Grid>
        )
      case 'onlyfy':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="API Key"
                value={config.apiKey || ''}
                onChange={handleConfigChange('apiKey')}
                required
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                  },
                }}
              />
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                You can find your API key in your Onlyfy account settings
              </Typography>
            </Grid>
          </Grid>
        )
      default:
        return null
    }
  }

  const validateConfig = () => {
    switch (selectedProvider) {
      case 'hr4you':
        return !!config.username && !!config.password
      case 'concludis-internal':
        return !!config.companyIdentifier && !!config.username && !!config.password
      case 'onlyfy':
        return !!config.apiKey
      default:
        return false
    }
  }

  const handleSubmit = async () => {
    if (!selectedProvider) return
    if (!validateConfig()) {
      showToast(
        intl.formatMessage({
          id: 'fill-required-fields',
          defaultMessage: 'Please fill all required fields',
        }),
        'error'
      )
      return
    }

    setLoading(true)
    try {
      const integrationData: IntegrationData = {
        config,
        active: true,
        type: selectedProvider,
        autoLeadPush: false, // Default to false for new integrations
      }

      await axiosInstance.post('/integrations/configurations', {
        ...integrationData,
        customerId: id,
      })
      showToast(
        intl.formatMessage({
          id: 'integration-saved',
          defaultMessage: 'Integration saved successfully',
        }),
        'success'
      )

      // Refresh the list of integrations
      await fetchExistingIntegrations()
      setSelectedProvider('')
      setConfig({})
    } catch (error) {
      console.error('Error saving integration:', error)
      showToast(
        intl.formatMessage({
          id: 'integration-save-error',
          defaultMessage: 'Error saving integration',
        }),
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteIntegration = async () => {
    if (!integrationToDelete) return

    setLoading(true)
    try {
      await axiosInstance.delete(`/integrations/configurations/${integrationToDelete}`)
      showToast(
        intl.formatMessage({
          id: 'integration-deleted',
          defaultMessage: 'Integration deleted successfully',
        }),
        'success'
      )
      // Refresh the list of integrations
      await fetchExistingIntegrations()
    } catch (error) {
      console.error('Error deleting integration:', error)
      showToast(
        intl.formatMessage({
          id: 'integration-delete-error',
          defaultMessage: 'Error deleting integration',
        }),
        'error'
      )
    } finally {
      setLoading(false)
      setConfirmDialogOpen(false)
      setIntegrationToDelete(null)
    }
  }

  const openConfirmDialog = (integrationId: string) => {
    setIntegrationToDelete(integrationId)
    setConfirmDialogOpen(true)
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false)
    setIntegrationToDelete(null)
  }

  const handleImportJobs = async (integrationId: string) => {
    setLoading(true)
    try {
      const response = await axiosInstance.get<ImportJobsResponse>(
        `/integrations/configurations/${integrationId}/import-jobs`
      )
      showToast(
        intl.formatMessage(
          { id: 'jobs-imported-successfully', defaultMessage: 'Jobs imported successfully' },
          { count: Number(response.data.count) }
        ),
        'success'
      )
      // Refresh the list of integrations to update job counts
      await fetchExistingIntegrations()
    } catch (error) {
      console.error('Error importing jobs:', error)
      showToast(
        intl.formatMessage({ id: 'jobs-import-error', defaultMessage: 'Error importing jobs' }),
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  // const handleSyncIntervalChange = async (integrationId: string, schedule?: string) => {
  //   setLoading(true)
  //   try {
  //     await axiosInstance.put(`/integrations/configurations/${integrationId}`, {
  //       schedule,
  //     })
  //     showToast(
  //       intl.formatMessage({
  //         id: 'sync-interval-updated',
  //         defaultMessage: 'Sync interval updated successfully',
  //       }),
  //       'success'
  //     )
  //     // Refresh the list of integrations
  //     await fetchExistingIntegrations()
  //   } catch (error) {
  //     console.error('Error updating sync interval:', error)
  //     showToast(
  //       intl.formatMessage({
  //         id: 'sync-interval-update-error',
  //         defaultMessage: 'Error updating sync interval',
  //       }),
  //       'error'
  //     )
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const getSyncIntervalMinutes = (schedule?: string) => {
    if (!schedule || schedule === '') return 0

    // Extract minutes from the cron expression: "*/30 * * * *"
    const match = schedule.match(/\*\/(\d+)\s+\*\s+\*\s+\*\s+\*/)
    return match && match[1] ? parseInt(match[1], 10) : 30 // Default to 30 if pattern doesn't match
  }

  // New functions for managing sync settings before saving
  const toggleSyncSettingsView = (integrationId: string) => {
    setShowSyncSettings((prev) => ({
      ...prev,
      [integrationId]: !prev[integrationId],
    }))
  }

  const updateSyncSettingsLocally = (
    integrationId: string,
    field: 'enabled' | 'minutes' | 'autoLeadPush',
    value: boolean | number
  ) => {
    setSyncSettings((prev) => ({
      ...prev,
      [integrationId]: {
        ...prev[integrationId],
        [field]: value,
      },
    }))
  }

  const saveSyncSettings = async (integrationId: string) => {
    const settings = syncSettings[integrationId]
    if (!settings) return

    // Generate correct cron pattern: */minutes * * * * (every X minutes)
    const schedule = settings.enabled ? `*/${settings.minutes} * * * *` : ''

    setLoading(true)
    try {
      await axiosInstance.put(`/integrations/configurations/${integrationId}`, {
        schedule,
        autoLeadPush: settings.autoLeadPush,
      })
      showToast(
        intl.formatMessage({
          id: 'sync-settings-updated',
          defaultMessage: 'Sync settings updated successfully',
        }),
        'success'
      )
      // Refresh the list of integrations
      await fetchExistingIntegrations()
      // Hide settings panel after save
      setShowSyncSettings((prev) => ({
        ...prev,
        [integrationId]: false,
      }))
    } catch (error) {
      console.error('Error updating sync settings:', error)
      showToast(
        intl.formatMessage({
          id: 'sync-settings-update-error',
          defaultMessage: 'Error updating sync settings',
        }),
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  if (loading || loadingIntegrations) return <Loading />

  return (
    <Container maxWidth="lg" className="integrationsContainer subFormContainer">
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: 'background.paper',
          mb: 4,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            mb: 3,
            fontWeight: 600,
            color: 'primary.main',
            fontSize: { xs: '1.5rem', md: '1.75rem' },
          }}
        >
          <FormattedMessage id="integrations" defaultMessage="Integrations" />
        </Typography>

        <Grid container spacing={4}>
          {existingIntegrations.length > 0 && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  mb: 2.5,
                  fontSize: '1.1rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  role="img"
                  aria-label="connected"
                  style={{ marginRight: '8px', fontSize: '1.2rem' }}
                >
                  🔌
                </span>
                <FormattedMessage
                  id="existing-integrations"
                  defaultMessage="Connected Integrations"
                />
                <Chip
                  label={existingIntegrations.length}
                  size="small"
                  sx={{ ml: 1, height: '20px', fontSize: '0.75rem' }}
                />
              </Typography>

              <Grid container spacing={2}>
                {existingIntegrations.map((integration) => {
                  const providerInfo = INTEGRATION_PROVIDERS.find(
                    (p) => p.value === integration.type
                  ) || { label: integration.type, icon: '🔗' }

                  return (
                    <Grid item xs={12} key={integration.id}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          transition: 'all 0.25s ease-in-out',
                          border: '1px solid',
                          borderColor: 'divider',
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor: 'primary.light',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                          <Grid container spacing={2}>
                            {/* Left column - Integration info */}
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              {/* Integration header */}
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Box
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: customColors.primary.soft,
                                    mr: 1.5,
                                    fontSize: '1.25rem',
                                  }}
                                >
                                  {providerInfo.icon}
                                </Box>
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 600, lineHeight: 1.2, mb: 0.5 }}
                                  >
                                    {providerInfo.label}
                                  </Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Chip
                                      label="ATS"
                                      size="small"
                                      sx={{
                                        borderRadius: '6px',
                                        fontWeight: 500,
                                        fontSize: '0.65rem',
                                        height: '20px',
                                      }}
                                    />
                                    {integration.active && (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          color: theme.palette.success.main,
                                          fontSize: '0.7rem',
                                        }}
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            bgcolor: theme.palette.success.main,
                                            mr: 0.5,
                                          }}
                                        />
                                        Active
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>

                              {/* Stats */}
                              <Grid container spacing={1.5} sx={{ mb: 2 }}>
                                <Grid item xs={12}>
                                  <Box
                                    sx={{
                                      p: 1.5,
                                      borderRadius: 1.5,
                                      bgcolor: customColors.background.neutral,
                                      height: '100%',
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      sx={{ display: 'block' }}
                                    >
                                      External ID
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 500,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {integration.externalId || '—'}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box
                                    sx={{
                                      p: 1.5,
                                      borderRadius: 1.5,
                                      bgcolor: integration.jobCount
                                        ? customColors.success.soft
                                        : customColors.text.disabled,
                                      height: '100%',
                                      color: integration.jobCount
                                        ? 'success.dark'
                                        : 'text.secondary',
                                    }}
                                  >
                                    <Typography variant="caption" sx={{ display: 'block' }}>
                                      Jobs Count
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                      {integration.jobCount || 0}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>

                              {/* Action Buttons (mobile only) */}
                              <Box
                                sx={{
                                  display: { xs: 'flex', md: 'none' },
                                  gap: 1,
                                  mt: 'auto',
                                  justifyContent: 'space-between',
                                  mb: 2,
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => toggleSyncSettingsView(integration.id)}
                                  sx={{
                                    flex: 1,
                                    borderRadius: 1.5,
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                      borderColor: 'primary.main',
                                      bgcolor: customColors.primary.soft,
                                    },
                                  }}
                                >
                                  {showSyncSettings[integration.id] ? 'Hide settings' : 'Configure'}
                                </Button>

                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleImportJobs(integration.id)}
                                  startIcon={
                                    <span role="img" aria-label="import">
                                      📥
                                    </span>
                                  }
                                  sx={{
                                    flex: 1,
                                    textTransform: 'none',
                                    borderRadius: 1.5,
                                    fontWeight: 500,
                                  }}
                                >
                                  Import Jobs
                                </Button>
                              </Box>
                            </Grid>

                            {/* Center column - Status */}
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <Box
                                sx={{
                                  p: 2,
                                  bgcolor: customColors.background.neutral,
                                  borderRadius: 2,
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
                                  Sync Status
                                </Typography>

                                {/* Sync status indicators */}
                                <Box sx={{ mb: 2.5 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                                    <Box
                                      component="span"
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: integration.schedule
                                          ? theme.palette.success.main
                                          : theme.palette.text.disabled,
                                        mr: 1,
                                      }}
                                    />
                                    <Typography variant="body2" color="text.primary">
                                      {integration.schedule ? 'Sync is active' : 'Sync is inactive'}
                                    </Typography>
                                  </Box>

                                  {!!integration.schedule && (
                                    <Box sx={{ ml: 2.5, mb: 1.5 }}>
                                      <Typography
                                        variant="caption"
                                        color="text.secondary"
                                        sx={{ display: 'block' }}
                                      >
                                        Runs every {getSyncIntervalMinutes(integration.schedule)}{' '}
                                        minutes
                                      </Typography>
                                    </Box>
                                  )}

                                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                                    <Box
                                      component="span"
                                      sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        bgcolor: integration.autoLeadPush
                                          ? theme.palette.info.main
                                          : theme.palette.text.disabled,
                                        mr: 1,
                                      }}
                                    />
                                    <Typography variant="body2" color="text.primary">
                                      {integration.autoLeadPush
                                        ? 'Auto lead push is enabled'
                                        : 'Auto lead push is disabled'}
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* Last update - placeholder */}
                                <Box sx={{ mt: 'auto' }}>
                                  <Typography variant="caption" color="text.secondary">
                                    Last updated: {new Date().toLocaleDateString()}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            {/* Right column - Actions */}
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              {/* Action Buttons (desktop only) */}
                              <Box
                                sx={{
                                  display: { xs: 'none', md: 'flex' },
                                  gap: 1.5,
                                  mb: 2,
                                  flexDirection: 'column',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  size="medium"
                                  color="primary"
                                  onClick={() => handleImportJobs(integration.id)}
                                  startIcon={
                                    <span role="img" aria-label="import">
                                      📥
                                    </span>
                                  }
                                  sx={{
                                    textTransform: 'none',
                                    borderRadius: 1.5,
                                    fontWeight: 500,
                                    py: 1,
                                  }}
                                >
                                  Import Jobs
                                </Button>

                                <Button
                                  variant="outlined"
                                  size="medium"
                                  onClick={() => toggleSyncSettingsView(integration.id)}
                                  sx={{
                                    borderRadius: 1.5,
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    py: 1,
                                    '&:hover': {
                                      borderColor: 'primary.main',
                                      bgcolor: customColors.primary.soft,
                                    },
                                  }}
                                >
                                  {showSyncSettings[integration.id]
                                    ? 'Hide sync settings'
                                    : 'Configure sync settings'}
                                </Button>

                                <Button
                                  variant="text"
                                  color="error"
                                  size="small"
                                  onClick={() => openConfirmDialog(integration.id)}
                                  sx={{
                                    fontSize: '0.8rem',
                                    opacity: 0.7,
                                    '&:hover': {
                                      opacity: 1,
                                      bgcolor: customColors.error.soft,
                                    },
                                  }}
                                >
                                  <FormattedMessage
                                    id="delete"
                                    defaultMessage="Delete Integration"
                                  />
                                </Button>
                              </Box>

                              {/* Mobile delete button */}
                              <Box
                                sx={{
                                  display: { xs: 'flex', md: 'none' },
                                  justifyContent: 'center',
                                  mb: 2,
                                }}
                              >
                                <Button
                                  variant="text"
                                  color="error"
                                  size="small"
                                  onClick={() => openConfirmDialog(integration.id)}
                                  sx={{
                                    fontSize: '0.8rem',
                                    opacity: 0.7,
                                    '&:hover': {
                                      opacity: 1,
                                      bgcolor: customColors.error.soft,
                                    },
                                  }}
                                >
                                  <FormattedMessage
                                    id="delete"
                                    defaultMessage="Delete Integration"
                                  />
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>

                          {/* Sync settings panel - full width */}
                          <Grow
                            in={showSyncSettings[integration.id]}
                            mountOnEnter
                            unmountOnExit
                            style={{ transformOrigin: '0 0 0' }}
                          >
                            <Box>
                              <Divider sx={{ mt: 3, mb: 3 }} />

                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={8} md={6} lg={5}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      mb: 2,
                                      fontWeight: 600,
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span
                                      role="img"
                                      aria-label="settings"
                                      style={{ marginRight: '8px', fontSize: '0.9rem' }}
                                    >
                                      ⚙️
                                    </span>
                                    Sync Configuration
                                  </Typography>

                                  <Paper
                                    elevation={0}
                                    sx={{
                                      p: 2.5,
                                      borderRadius: 2,
                                      border: '1px solid',
                                      borderColor: 'divider',
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      {/* Enable periodic sync toggle */}
                                      <Grid item xs={12}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            py: 0.5,
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ fontWeight: 500 }}
                                          >
                                            Enable periodic sync
                                          </Typography>
                                          <Switch
                                            size="small"
                                            checked={syncSettings[integration.id]?.enabled || false}
                                            onChange={(e) =>
                                              updateSyncSettingsLocally(
                                                integration.id,
                                                'enabled',
                                                e.target.checked
                                              )
                                            }
                                            color="primary"
                                          />
                                        </Box>
                                      </Grid>

                                      {/* Sync interval */}
                                      {syncSettings[integration.id]?.enabled && (
                                        <Grid item xs={12}>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              px: 1,
                                              py: 1.5,
                                              bgcolor: customColors.background.neutral,
                                              borderRadius: 1.5,
                                            }}
                                          >
                                            <Typography variant="body2" color="text.secondary">
                                              Sync interval:
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                              <TextField
                                                size="small"
                                                type="number"
                                                variant="outlined"
                                                value={syncSettings[integration.id]?.minutes || 30}
                                                onChange={(e) => {
                                                  const minutes = parseInt(e.target.value, 10)
                                                  if (minutes > 0) {
                                                    updateSyncSettingsLocally(
                                                      integration.id,
                                                      'minutes',
                                                      minutes
                                                    )
                                                  }
                                                }}
                                                inputProps={{
                                                  min: 1,
                                                  style: {
                                                    width: '45px',
                                                    textAlign: 'center',
                                                  },
                                                }}
                                                sx={{
                                                  '& .MuiOutlinedInput-root': {
                                                    borderRadius: 1,
                                                    bgcolor: 'background.paper',
                                                  },
                                                }}
                                              />
                                              <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ ml: 1 }}
                                              >
                                                minutes
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      )}

                                      {/* Auto push toggle */}
                                      <Grid item xs={12}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            py: 0.5,
                                          }}
                                        >
                                          <Tooltip
                                            title="When enabled, new leads will be automatically pushed to this ATS integration"
                                            placement="top"
                                            arrow
                                          >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                              <Typography
                                                variant="body2"
                                                color="text.primary"
                                                sx={{ fontWeight: 500 }}
                                              >
                                                Auto push leads
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                color="text.secondary"
                                                sx={{ ml: 0.5 }}
                                              >
                                                ⓘ
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                          <Switch
                                            size="small"
                                            checked={
                                              syncSettings[integration.id]?.autoLeadPush || false
                                            }
                                            onChange={(e) =>
                                              updateSyncSettingsLocally(
                                                integration.id,
                                                'autoLeadPush',
                                                e.target.checked
                                              )
                                            }
                                            color="primary"
                                          />
                                        </Box>
                                      </Grid>

                                      {/* Save button */}
                                      <Grid item xs={12} sx={{ mt: 1 }}>
                                        <Button
                                          fullWidth
                                          variant="contained"
                                          size="medium"
                                          color="primary"
                                          onClick={() => saveSyncSettings(integration.id)}
                                          sx={{
                                            textTransform: 'none',
                                            borderRadius: 1.5,
                                            py: 0.75,
                                          }}
                                        >
                                          Save Settings
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grow>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              sx={{
                position: 'relative',
                mb: 2.5,
                mt: existingIntegrations.length > 0 ? 4 : 0,
              }}
            >
              <Divider sx={{ mb: 4, mt: existingIntegrations.length > 0 ? 2 : 0 }} />

              <Typography
                variant="h6"
                sx={{
                  mb: 3,
                  fontSize: '1.1rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  role="img"
                  aria-label="add"
                  style={{ marginRight: '8px', fontSize: '1.2rem' }}
                >
                  ➕
                </span>
                <FormattedMessage id="add-integration" defaultMessage="Add New Integration" />
              </Typography>

              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  bgcolor: customColors.background.neutral,
                  border: '1px dashed',
                  borderColor: 'divider',
                }}
              >
                <Box sx={{ maxWidth: 600, mx: 'auto' }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center' }}
                  >
                    Connect your ATS provider to automatically import jobs and synchronize data
                  </Typography>

                  <FormControl
                    fullWidth
                    sx={{
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 1.5,
                        bgcolor: 'background.paper',
                      },
                    }}
                  >
                    <InputLabel id="integration-provider-label">
                      <FormattedMessage
                        id="select-integration-provider"
                        defaultMessage="Select Integration Provider"
                      />
                    </InputLabel>
                    <Select
                      labelId="integration-provider-label"
                      id="integration-provider"
                      value={selectedProvider}
                      label={intl.formatMessage({
                        id: 'select-integration-provider',
                        defaultMessage: 'Select Integration Provider',
                      })}
                      onChange={handleProviderChange}
                    >
                      {INTEGRATION_PROVIDERS.map((provider) => {
                        const isProviderUsed = existingIntegrations.some(
                          (integration) => integration.type === provider.value
                        )
                        return (
                          <MenuItem
                            key={provider.value}
                            value={provider.value}
                            disabled={isProviderUsed}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              py: 1.5,
                            }}
                          >
                            <Box component="span" sx={{ mr: 1.5, fontSize: '1.1rem' }}>
                              {provider.icon}
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>{provider.label}</Box>
                            {isProviderUsed && (
                              <Chip
                                label={intl.formatMessage({
                                  id: 'already-configured',
                                  defaultMessage: 'Already Connected',
                                })}
                                size="small"
                                sx={{
                                  ml: 1,
                                  fontSize: '0.65rem',
                                  height: '20px',
                                  bgcolor: 'action.hover',
                                }}
                              />
                            )}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>

                  {selectedProvider && (
                    <Fade in={!!selectedProvider}>
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            mb: 2,
                            fontWeight: 600,
                          }}
                        >
                          Connection Details
                        </Typography>

                        <Box
                          sx={{
                            p: 2.5,
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          {renderConfigFields()}
                        </Box>
                      </Box>
                    </Fade>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      onClick={handleSubmit}
                      disabled={!selectedProvider || !validateConfig()}
                      sx={{
                        px: 4,
                        py: 1,
                        borderRadius: 2,
                        textTransform: 'none',
                        fontWeight: 600,
                      }}
                    >
                      <FormattedMessage
                        id="save-integration"
                        defaultMessage="Connect Integration"
                      />
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: 500,
          },
        }}
      >
        <DialogTitle id="confirm-dialog-title" sx={{ pb: 1, pt: 2.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}>
            <span
              role="img"
              aria-label="warning"
              style={{ marginRight: '8px', fontSize: '1.2rem' }}
            >
              ⚠️
            </span>
            {intl.formatMessage({
              id: 'delete-integration-confirm-title',
              defaultMessage: 'Delete Integration',
            })}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description" sx={{ color: 'text.primary', mb: 1 }}>
            {intl.formatMessage({
              id: 'delete-integration-confirm',
              defaultMessage: 'Are you sure you want to delete this integration?',
            })}
          </DialogContentText>
          <Box
            sx={{
              p: 2,
              borderRadius: 1.5,
              bgcolor: customColors.info.light,
              color: 'info.dark',
              fontSize: '0.875rem',
              mt: 2,
            }}
          >
            <span role="img" aria-label="info" style={{ marginRight: '6px' }}>
              ℹ️
            </span>
            {intl.formatMessage({
              id: 'delete-integration-jobs-preserved',
              defaultMessage:
                'The integration will be deleted from both our system and the external ATS service, but any related jobs that have been imported will be preserved.',
            })}
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={closeConfirmDialog}
            variant="outlined"
            sx={{
              borderRadius: 1.5,
              textTransform: 'none',
              fontWeight: 500,
            }}
          >
            {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </Button>
          <Button
            onClick={handleDeleteIntegration}
            color="error"
            variant="contained"
            disableElevation
            sx={{
              borderRadius: 1.5,
              textTransform: 'none',
              fontWeight: 500,
              ml: 1,
            }}
          >
            {intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Integrations
